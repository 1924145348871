import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

import Footer from '../components/footer'
import Layout from '../components/layout'
import Content from '../components/content'
import Seo from '../components/seo'

const Textblock = styled.div`
background-color: #fff;
padding: 18px 5vw 0 0;
h1 {
  line-height: 1.15em;
  font-size: 1.0625rem;
  font-weight: 800;
  letter-spacing: .75px;
  height: 8vh;
}

b {
    font-size: .9125rem;
    letter-spacing: 1.5px;
    font-weight: 800;
}
img {
    margin-top: 7.5vh !important;
}
`

const NotFoundPage = () => {
  return (
    <>
    <Layout>
      <Seo title="Page not found"/>
      <Content>
      <Textblock>
      <h1>Page not found</h1>
      <p>
        Sorry we couldn’t find what you were looking for.
        <br />
        <br />
        <Link to="/">Visit our homepage</Link>.
      </p>
      </Textblock>
    </Content>
    </Layout>
    <Footer />
    </>
  )
}

export default NotFoundPage
